export const currencies = [
  {
    value: 'usd',
    title: `USD $`,
    symbol: '$',
  },
  {
    value: 'eur',
    title: `EUR €`,
    symbol: '€',
  },
]

export function findCurrency(value: string) {
  return currencies.find((e) => e.value === value)
}

export function getCurrencySymbol(currency: string) {
  const cur = findCurrency(currency)
  return cur ? cur.symbol : ''
}

export function getPricedNumber(price?: number, currency?: string) {
  const r = price ? price : 0
  if (currency) {
    const cur = findCurrency(currency)
    if (cur) return `${r} ${cur.symbol}`
  }
  return `${r}`
}

export function getSelectPriceRanges(currency: string) {
  const { t } = useNuxtApp().$i18n // recommended workaround useNuxtApp().$i18n when const { t } = useI18n() not working in composables
  const cur = findCurrency(currency)
  const r = cur ? cur.symbol : ''
  return [
    {
      title: t('All'),
      value: 'all',
      min: 0,
    },
    {
      title: `FREE 0${r}`,
      value: '0',
      min: 0,
      max: 0,
    },
    {
      title: `1 ${t('to')} 5${r}`,
      value: '1-5',
      min: 100,
      max: 500,
    },
    {
      title: `5 ${t('to')} 10${r}`,
      value: '5-10',
      min: 500,
      max: 1000,
    },
    {
      title: `10 ${t('to')} 15${r}`,
      value: '10-15',
      min: 1000,
      max: 1500,
    },
    {
      title: `15 ${t('to')} 20${r}`,
      value: '15-20',
      min: 1500,
      max: 2000,
    },
    {
      title: `> 20${r}`,
      value: '20+',
      min: 2000,
    },
  ]
}

export function getCurrencyFromEuropeanCountry(locale: string) {
  // currency
  const euroLocaleCountries = [
    'fr', // French
    'de', // German
    'es', // Spanish
    'it', // Italian
    'pt', // Portuguese
    'nl', // Dutch
    'el', // Greek
    'gr', // Greek
    'fi', // Finnish
    'et', // Estonian
    'lv', // Latvian
    'lt', // Lithuanian
    'mt', // Maltese
    'sk', // Slovak
    'sl', // Slovenian
    'ga', // Irish
    'hr', // Croatian
    'sv', // Swedish
    'se', // Swedish
    'da', // Danish
    'dk', // Danish
    'nb', // Norwegian / Norway
    'no', // Norwegian / Norway
    'pl', // Polish
    'cs', // Czech
    'cz', // Czech
    'hu', // Hungarian
    'bg', // Bulgarian
    'ro', // Romanian
  ]
  const found = euroLocaleCountries.find((e) => e === locale.toLowerCase())
  if (found) return 'eur'
  return 'usd'
}
